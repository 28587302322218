
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.signature-pad {
  .canvas-container {
    border: 1px solid $primary;
    line-height: 0;
  }
}
